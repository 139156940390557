import Swal from 'sweetalert2';

function clearSweetAlert(e: PageTransitionEvent) {
  if (e.persisted) {
    Swal.close();
  }
}

window.addEventListener('pagehide', clearSweetAlert);

function closeRemodal(e: PageTransitionEvent) {
  if (e.persisted) {
    (jQuery as any).remodal.lookup.forEach(m => m.close());
  }
}

if (typeof jQuery !== 'undefined') {
  window.addEventListener('pagehide', closeRemodal);
}
